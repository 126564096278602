import { useRef, useLayoutEffect, useEffect } from "react";

import $ from "jquery";
import OrderDialog from "./OrderDialog";
import InfoModal from "./InfoModal";
import Cookies from "universal-cookie";
import { load } from "recaptcha-v3";
//import { SketchPicker } from "react-color";
import { SketchPicker } from "react-color";
import "../fonts/ardecode.ttf";
import "./CustomFonts.css";
import "./CurtainMenu.css";

const conf = require("../config");

const BaseCanvas = () => {
  const cookies = new Cookies();
  const canvaselement = useRef(null);
  const textInput = useRef(null);
  const textInput2 = useRef(null);
  const textObject = useRef(null);
  const textObject2 = useRef(null);
  //const [bg, setBg] = useState(null);
  let bigImage = new Image();
  let miniImage = new Image();
  let miniImage2 = new Image();
  let bigimagebase64;
  let miniimagebase64;
  let miniimage2base64;
  let basectx;
  let xPosMiniImage = 0;
  let yPosMiniImage = 0;
  let xPosMiniImage2 = 0;
  let yPosMiniImage2 = 0;
  let imgWidth = 300;
  let imgHeight = 150;
  let imgWidth2 = 300;
  let imgHeight2 = 150;
  let textWidth = 0;
  let textHeight = 30;
  let textWidth2 = 0;
  let textHeight2 = 30;
  let dragMiniImage = false;
  let dragMiniImage2 = false;
  let dragText = false;
  let dragText2 = false;
  let text1Color = "#000000";
  let text2Color = "#000000";
  let text1Font = "ARDECODE";
  let text2Font = "ARDECODE";
  let selectedMaterial;
  /*
  const openNav = () => {
    document.getElementById("myNav").style.width = "20%";
    document.getElementById("menu-button").style.display = "none";
  };
*/
  /* Close when someone clicks on the "x" symbol inside the overlay */
  /*
  const closeNav = () => {
    document.getElementById("myNav").style.width = "0%";
    document.getElementById("menu-button").style.display = "inline-block";
  };
  */
  const w =
    window.innerWidth ||
    document.documentElement.clientWidth ||
    document.body.clientWidth;

  const h =
    window.innerHeight - 100 ||
    document.documentElement.clientHeight - 100 ||
    document.body.clientHeight - 100;
  const addText = () => {
    textObject.current = {
      text: textInput.current.value,
      font: textHeight + "px " + text1Font,
      textPosX: 10,
      textPosY: 50,
    };
    mainDraw();

    text1Font = "Arial";
    textObject.current = {
      text: textInput.current.value,
      font: textHeight + "px " + text1Font,
      textPosX: 10,
      textPosY: 50,
    };
    mainDraw();
  };
  const addText2 = () => {
    textObject2.current = {
      text: textInput2.current.value,
      font: textHeight2 + "px " + text2Font,
      textPosX: 10,
      textPosY: 50,
    };
    mainDraw();
    text2Font = "Arial";
    textObject2.current = {
      text: textInput2.current.value,
      font: textHeight2 + "px " + text2Font,
      textPosX: 10,
      textPosY: 50,
    };
    mainDraw();
  };
  const reloadApp = () => {
    window.location.reload();
  };

  const openFileDialogMain = () => {
    document.getElementById("mainimagefileinputid").click();
  };
  const openFileDialogMini = () => {
    document.getElementById("miniimagefileinputid").click();
  };
  const openFileDialogMini2 = () => {
    document.getElementById("miniimagefileinputid2").click();
  };
  const miniImageSizePlus = () => {
    imgWidth = imgWidth * 1.1;
    imgHeight = imgHeight * 1.1;
    mainDraw();
  };
  const miniImageSizeMinus = () => {
    imgWidth = imgWidth / 1.1;
    imgHeight = imgHeight / 1.1;
    mainDraw();
  };
  const miniImageSizePlus2 = () => {
    imgWidth2 = imgWidth2 * 1.1;
    imgHeight2 = imgHeight2 * 1.1;
    mainDraw();
  };
  const miniImageSizeMinus2 = () => {
    imgWidth2 = imgWidth2 / 1.1;
    imgHeight2 = imgHeight2 / 1.1;
    mainDraw();
  };
  const mainImageSizeMinus = () => {
    bigImage.width = bigImage.width / 1.1;
    bigImage.height = bigImage.height / 1.1;

    mainDraw();
  };
  /*
  Näitä voi käyttää apuna jos yrittää kuvan kääntöä
  const mainImageRotate = () => {
    console.log("mainImageRotate");
    basectx.rotate(DegToRad(5));
    // let deg = 10;
    // $("#imageImg").style = "transform: rotate(10deg)";
    //$("#imageImg").rotate(90);
    //imageRotation += -5;
    // $("#imageImg").css("transform", "rotate(" + imageRotation + "deg)");
    // const img = document.querySelector("#imageImg");
    // bigImage.src = getDataUrl(img);

    mainDraw();
  };
  const DegToRad = (d) => {
    // Converts degrees to radians
    return d * 0.01745;
  };
  const getDataUrl = function (img) {
    let canvas = document.createElement("canvas");
    let ctx = canvas.getContext("2d");

    canvas.width = img.width;
    canvas.height = img.height;
    ctx.drawImage(img, 0, 0);

    // If the image is not png, the format
    // must be specified here
    return canvas.toDataURL();
  };
  */
  const mainImageSizePlus = () => {
    bigImage.width = bigImage.width * 1.1;
    bigImage.height = bigImage.height * 1.1;
    mainDraw();
  };

  const textSizePlus = () => {
    if (textObject.current) {
      textHeight = textHeight * 1.1;
      textObject.current.font = textHeight + "px " + text1Font;
      mainDraw();
    }
  };
  const textSizeMinus = () => {
    if (textObject.current) {
      textHeight = textHeight / 1.1;
      textObject.current.font = textHeight + "px " + text1Font;
      mainDraw();
    }
  };
  const textSizePlus2 = () => {
    if (textObject2.current) {
      textHeight2 = textHeight2 * 1.1;
      textObject2.current.font = textHeight2 + "px " + text2Font;
      mainDraw();
    }
  };
  const textSizeMinus2 = () => {
    if (textObject2.current) {
      textHeight2 = textHeight2 / 1.1;
      textObject2.current.font = textHeight2 + "px " + text2Font;
      mainDraw();
    }
  };

  const startOrder = () => {
    $("#order-dialog").submit(function (e) {
      e.preventDefault();
    });
    $("#order-dialog").show();
  };
  const sendOrder = (e) => {
    let formData = new FormData(e.target);
    let formDataObj = Object.fromEntries(formData.entries());
    formDataObj["subject"] = conf.subject;
    formDataObj["type"] = 2;
    let images = [];
    let canvasobject = new Object();
    canvasobject.path = canvaselement.current.toDataURL();
    images.push(canvasobject);
    if (bigimagebase64) {
      let object = new Object();
      object.path = bigimagebase64;
      images.push(object);
    }
    if (miniimagebase64) {
      let object2 = new Object();
      object2.path = miniimagebase64;
      images.push(object2);
    }
    if (miniimage2base64) {
      let object3 = new Object();
      object3.path = miniimage2base64;
      images.push(object3);
    }
    formDataObj["attachments"] = images;
    if (selectedMaterial) formDataObj["Materiaali"] = selectedMaterial;
    if (textObject && textObject.current) {
      formDataObj["Teksti 1"] = textObject.current.text;
      formDataObj["Teksti 1 Fontti"] = textObject.current.font;
      formDataObj["Teksti 1 Väri"] = text1Color;
    }
    if (textObject2 && textObject2.current) {
      formDataObj["Teksti 2"] = textObject2.current.text;
      formDataObj["Teksti 2 Fontti"] = textObject2.current.font;
      formDataObj["Teksti 2 Väri"] = text2Color;
    }
    $("#sending-modal").modal();
    sendEmail(formDataObj);
    //Kuva merkattavasta esineestä, muuttujassa bigimage ,OK

    //console.log("bigimagebase64:", bigimagebase64);
    //console.log("miniimagebase64:", miniimagebase64);
    //console.log("miniimage2base64:", miniimage2base64);
    //Kuva logosta tms, miniimage, OK
    //Kuva logosta tms, miniimage2, OK
    //MAteriaalivalinta
    //Teksti 1 OK
    //Teksti 1 fontti OK
    //Teksti 1 väri OK
    //Teksti 2 OK
    //Teksti 2 fontti OK
    //Teksti 2 väri OK
    //Sommittelun lopputuloksesta kuva
    //canvaselement.current.toDataURL()
    //downloadOrder();
  };

  const sendEmail = (formdataobj) => {
    load("6LcXr0kaAAAAAGd_-wbQ39dt6FSnEz-vAA4SiFhR").then((recaptcha) => {
      recaptcha.execute().then((token) => {
        formdataobj["token"] = token;
        let data = JSON.stringify(formdataobj);
        fetch(conf.requrl, {
          method: "POST",
          body: data,
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        })
          .then((response) => response.json())
          .then((response) => {
            if (response.status === "success") {
              //setsuccess(1);
              // $("#order-dialog").reset();
              $("#order-dialog").hide();
              $("#info-modal").modal();
              $("#sending-modal").hide();
              //e.resetForm();
            } else if (response.status === "fail") {
              $("#sending-modal").hide();
              $("#error-modal").modal();
            }
          });
      });
    });
  };
  const materialSelected = (e) => {
    const selectedText = e.target.innerHTML;
    selectedMaterial = selectedText;
    $("#dropdownMenuLink").html(selectedText);
  };
  const setText1Color = (e) => {
    const selectedText = e.target.innerHTML;
    $("#btnGroupDrop1").html(selectedText);
    text1Color = e.target.value;
    mainDraw();
  };
  const setText2Color = (e) => {
    const selectedText = e.target.innerHTML;
    $("#btnGroupDrop2").html(selectedText);
    text2Color = e.target.value;
    mainDraw();
  };
  const setText1Font = (e) => {
    const selectedFont = e.target.innerHTML;
    $("#btnGroupFontDrop").html(selectedFont);
    text1Font = e.target.value;
    if (textObject.current) {
      textObject.current.font = textHeight + "px " + text1Font;
      textObject.current.textPosX += 10;
      textObject.current.textPosY += 10;
    }

    mainDraw();
  };
  const setText2Font = (e) => {
    const selectedFont = e.target.innerHTML;
    $("#btnGroupFontDrop2").html(selectedFont);
    text2Font = e.target.value;
    if (textObject2.current)
      textObject2.current.font = textHeight2 + "px " + text2Font;
    mainDraw();
  };
  const setAccept = () => {
    cookies.set("accept-terms-of-use", "true", {
      path: "/",
      expires: new Date(new Date().setFullYear(new Date().getFullYear() + 1)),
    });
  };
  const setInstructionsRead = () => {
    cookies.set("instructions", "true", {
      path: "/",
      expires: new Date(new Date().setFullYear(new Date().getFullYear() + 1)),
    });
  };
  const getInstructions = () => {
    $("#instructions-modal").modal();
  };

  const showColor1 = () => {
    $("#color-palette1").toggleClass("d-none");
  };
  const showColor2 = () => {
    $("#color-palette2").toggleClass("d-none");
  };
  const handleColorChange1 = ({ hex }) => {
    console.log(hex);
    //const selectedText = e.target.innerHTML;
    //$("#btnGroupDrop2").html(selectedText);
    text1Color = hex;
    mainDraw();
    //$("#color-palette1").addClass("d-none");
  };
  const handleColorChange2 = ({ hex }) => {
    console.log(hex);
    // const selectedText = e.target.innerHTML;
    // $("#btnGroupDrop2").html(selectedText);
    text2Color = hex;
    mainDraw();
    //$("#color-palette1").addClass("d-none");
  };
  useEffect(() => {
    //Calc if click is on top of miniimage
    const clickhandler = (e) => {
      let curXPos = e.clientX;
      let curYPos = e.clientY;
      if (checkIfOverImage(curXPos, curYPos)) {
        /*
        console.log("curXPos", curXPos);
        console.log("curYPos", curYPos);

        console.log("xPosMiniImage", xPosMiniImage);
        console.log("yPosMiniImage", yPosMiniImage);
        console.log("imgWidth", imgWidth);
        console.log("imgHeight", imgHeight);
        console.log("xPosMiniImage2", xPosMiniImage2);
        console.log("yPosMiniImage2", yPosMiniImage2);
        console.log("imgWidth2", imgWidth2);
        console.log("imgHeight2", imgHeight2);
*/
        checkIfImageDrag(curXPos, curYPos);
        checkIfImageDrag2(curXPos, curYPos);
        checkIfTextDrag(curXPos, curYPos);
        checkIfTextDrag2(curXPos, curYPos);
      }
    };

    const checkIfOverImage = (curXPos, curYPos) => {
      if (curXPos <= bigImage.width && curYPos <= bigImage.height) return true;
      else return false;
    };
    const checkIfImageDrag = (curXPos, curYPos) => {
      if (miniImage.src) {
        if (
          curXPos >= xPosMiniImage &&
          curXPos <= xPosMiniImage + imgWidth &&
          curYPos >= yPosMiniImage &&
          curYPos <= yPosMiniImage + imgHeight
        ) {
          dragMiniImage = true;
          dragMiniImage2 = false;
          dragText = false;
          dragText2 = false;
        }
      }
    };
    const checkIfImageDrag2 = (curXPos, curYPos) => {
      if (miniImage2.src) {
        if (
          curXPos >= xPosMiniImage2 &&
          curXPos <= xPosMiniImage2 + imgWidth2 &&
          curYPos >= yPosMiniImage2 &&
          curYPos <= yPosMiniImage2 + imgHeight2
        ) {
          dragMiniImage2 = true;
          dragMiniImage = false;
          dragText = false;
          dragText2 = false;
        }
      }
    };
    const checkIfTextDrag = (curXPos, curYPos) => {
      if (textObject.current) {
        if (
          curXPos >= textObject.current.textPosX &&
          curXPos <= textObject.current.textPosX + textWidth &&
          curYPos >= textObject.current.textPosY &&
          curYPos <= textObject.current.textPosY + textHeight
        ) {
          dragText = true;
          dragMiniImage = false;
          dragMiniImage2 = false;
          dragText2 = false;
        }
      }
    };
    const checkIfTextDrag2 = (curXPos, curYPos) => {
      if (textObject2.current) {
        if (
          curXPos >= textObject2.current.textPosX &&
          curXPos <= textObject2.current.textPosX + textWidth2 &&
          curYPos >= textObject2.current.textPosY &&
          curYPos <= textObject2.current.textPosY + textHeight2
        ) {
          dragText2 = true;
          dragText = false;
          dragMiniImage = false;
          dragMiniImage2 = false;
        }
      }
    };
    //Update position based on mousemove, draw canvas everytime
    const movehandler = (e) => {
      // console.log("drag", drag);
      if (dragMiniImage) {
        //voisi joskus yrittää laskea tarkan x ja y korjauksen riippuen missä kohtaa kuvan päällä ollaan
        let xFix = imgWidth / 2;
        let yFix = imgHeight / 2;
        xPosMiniImage = e.clientX - xFix;
        yPosMiniImage = e.clientY - yFix;
        mainDraw();
      }
      if (dragMiniImage2) {
        //voisi joskus yrittää laskea tarkan x ja y korjauksen riippuen missä kohtaa kuvan päällä ollaan
        let xFix = imgWidth2 / 2;
        let yFix = imgHeight2 / 2;
        xPosMiniImage2 = e.clientX - xFix;
        yPosMiniImage2 = e.clientY - yFix;
        mainDraw();
      }
      if (dragText) {
        let xFix = textWidth / 2;
        let yFix = textHeight / 2;
        textObject.current.textPosX = e.clientX - xFix;
        textObject.current.textPosY = e.clientY - yFix;
        mainDraw();
      }
      if (dragText2) {
        let xFix = textWidth2 / 2;
        let yFix = textHeight2 / 2;
        textObject2.current.textPosX = e.clientX - xFix;
        textObject2.current.textPosY = e.clientY - yFix;
        mainDraw();
      }
    };
    const mouseUpHandler = (e) => {
      dragMiniImage = false;
      dragMiniImage2 = false;
      dragText = false;
      dragText2 = false;
    };
    document.addEventListener("mousedown", clickhandler);
    document.addEventListener("mousemove", movehandler);
    document.addEventListener("mouseup", mouseUpHandler);
    return () => {
      document.removeEventListener("mousedown", clickhandler);
      document.removeEventListener("mousemove", movehandler);
      document.removeEventListener("mouseup", mouseUpHandler);
    };
  });

  useLayoutEffect(() => {
    basectx = canvaselement.current.getContext("2d");

    if (cookies.get("accept-terms-of-use") == null) {
      $("#terms-of-use").modal();
    }
    if (cookies.get("instructions") == null) {
      $("#instructions-modal").modal();
    }
  });
  const mainDraw = () => {
    basectx.clearRect(0, 0, basectx.canvas.width, basectx.canvas.height);
    if (bigImage) {
      basectx.drawImage(bigImage, 0, 0, bigImage.width, bigImage.height);
    }
    if (textObject.current) {
      basectx.font = textObject.current.font;
      basectx.fillStyle = text1Color;
      basectx.fillText(
        textObject.current.text,
        textObject.current.textPosX,
        textObject.current.textPosY
      );
      textWidth = basectx.measureText(textObject.current.text).width;
    }
    if (textObject2.current) {
      basectx.font = textObject2.current.font;
      basectx.fillStyle = text2Color;
      basectx.fillText(
        textObject2.current.text,
        textObject2.current.textPosX,
        textObject2.current.textPosY
      );
      textWidth2 = basectx.measureText(textObject2.current.text).width;
    }
    if (miniImage)
      basectx.drawImage(
        miniImage,
        xPosMiniImage,
        yPosMiniImage,
        imgWidth,
        imgHeight
      );
    if (miniImage2)
      basectx.drawImage(
        miniImage2,
        xPosMiniImage2,
        yPosMiniImage2,
        imgWidth2,
        imgHeight2
      );
  };
  return (
    <>
      <div id="myNav" className="overlay">
        <div className="overlay-content">
          <div className="form-group">
            <button
              id="select-main-image-button"
              className="btn btn-secondary overlay-button"
              onClick={openFileDialogMain}
            >
              1.Valitse esine tai tuote
            </button>
          </div>
          <div className="form-group">
            <div
              className="btn-group  overlay-button"
              role="group"
              aria-label="Button group with nested dropdown"
            >
              <button
                type="button"
                onClick={mainImageSizePlus}
                className="btn  btn-secondary"
              >
                Koko +
              </button>
              <button
                type="button"
                onClick={mainImageSizeMinus}
                className="btn  btn-secondary"
              >
                Koko -
              </button>
            </div>
          </div>

          <div className="form-group">
            <button
              className="btn dropdown-toggle btn-secondary overlay-button"
              href="#"
              role="button"
              id="dropdownMenuLink"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              2.Valitse materiaali
            </button>

            <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
              <option className="dropdown-item" onClick={materialSelected}>
                Puu
              </option>
              <option className="dropdown-item" onClick={materialSelected}>
                Metalli
              </option>
              <option className="dropdown-item" onClick={materialSelected}>
                Muovi
              </option>
              <option className="dropdown-item" onClick={materialSelected}>
                Nahka
              </option>
            </div>
          </div>
          <div className="form-group">
            <button
              className="btn btn-secondary overlay-button"
              onClick={openFileDialogMini}
            >
              3.Valitse merkkaus 1
            </button>
          </div>
          <div className="form-group">
            <div
              className="btn-group  overlay-button"
              role="group"
              aria-label="Button group with nested dropdown"
            >
              <button
                type="button"
                onClick={miniImageSizePlus}
                className="btn  btn-secondary"
              >
                Koko +
              </button>
              <button
                type="button"
                onClick={miniImageSizeMinus}
                className="btn  btn-secondary"
              >
                Koko -
              </button>
            </div>
          </div>
          <div className="form-group">
            <button
              className="btn  btn-secondary  overlay-button"
              onClick={openFileDialogMini2}
            >
              Valitse merkkaus 2
            </button>
          </div>
          <div className="form-group">
            <div
              className="btn-group  overlay-button"
              role="group"
              aria-label="Button group with nested dropdown"
            >
              <button
                type="button"
                onClick={miniImageSizePlus2}
                className="btn  btn-secondary"
              >
                Koko +
              </button>
              <button
                type="button"
                onClick={miniImageSizeMinus2}
                className="btn  btn-secondary"
              >
                Koko -
              </button>
            </div>
          </div>
          <div className="form-group">
            <input
              type="text"
              className="form-control"
              placeholder="Teksti 1 tähän"
              ref={textInput}
            />
          </div>
          <div className="form-group">
            <button
              className="btn  btn-secondary  overlay-button"
              onClick={addText}
            >
              4.Lisää teksti 1
            </button>
          </div>

          <div className="form-group">
            <div
              className="btn-group btn-group-curtain  overlay-button"
              role="group"
              aria-label="Button group with nested dropdown"
            >
              <button
                type="button"
                onClick={textSizePlus}
                className="btn  btn-secondary"
              >
                Koko+
              </button>
              <button
                type="button"
                onClick={textSizeMinus}
                className="btn  btn-secondary"
              >
                Koko-
              </button>

              <div className="btn-group " role="group">
                <button
                  id="btncolor1"
                  type="button"
                  className="btn btn-secondary"
                  onClick={showColor1}
                >
                  Väri
                </button>
              </div>
              <div className="btn-group " role="group">
                <button
                  id="btnGroupFontDrop"
                  type="button"
                  className="btn dropdown-toggle btn-secondary"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  Arial
                </button>
                <div
                  className="dropdown-menu"
                  aria-labelledby="btnGroupFontDrop"
                >
                  <option
                    className="dropdown-item"
                    value="Arial"
                    onClick={setText1Font}
                  >
                    Arial
                  </option>
                  <option
                    className="dropdown-item"
                    value="Times New Roman"
                    onClick={setText1Font}
                  >
                    Times New Roman
                  </option>
                  <option
                    className="dropdown-item"
                    value="ARDECODE"
                    onClick={setText1Font}
                  >
                    AR Decode
                  </option>{" "}
                  <option
                    className="dropdown-item"
                    value="georgia"
                    onClick={setText1Font}
                  >
                    Georgia
                  </option>{" "}
                </div>
              </div>
            </div>
          </div>
          <div className="d-none" id="color-palette1">
            <SketchPicker
              color="#000000"
              onChangeComplete={handleColorChange1}
              width="280px"
            />
          </div>
          <div className="form-group">
            <input
              type="text"
              className="form-control"
              placeholder="Teksti 2 tähän"
              ref={textInput2}
            />
          </div>
          <div className="form-group">
            <button
              className="btn  btn-secondary  overlay-button"
              onClick={addText2}
            >
              Lisää teksti 2
            </button>
          </div>
          <div className="form-group">
            <div
              className="btn-group btn-group-curtain"
              role="group"
              aria-label="Button group with nested dropdown"
            >
              <button
                type="button"
                onClick={textSizePlus2}
                className="btn  btn-secondary"
              >
                Koko+
              </button>
              <button
                type="button"
                onClick={textSizeMinus2}
                className="btn  btn-secondary"
              >
                Koko-
              </button>

              <div className="btn-group" role="group">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={showColor2}
                >
                  Väri
                </button>
              </div>
              <div className="btn-group " role="group">
                <button
                  id="btnGroupFontDrop2"
                  type="button"
                  className="btn dropdown-toggle btn-secondary"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  Arial
                </button>
                <div
                  className="dropdown-menu"
                  aria-labelledby="btnGroupFontDrop2"
                >
                  <option
                    className="dropdown-item"
                    value="Arial"
                    onClick={setText2Font}
                  >
                    Arial
                  </option>
                  <option
                    className="dropdown-item"
                    value="Times New Roman"
                    onClick={setText2Font}
                  >
                    Times New Roman
                  </option>
                  <option
                    className="dropdown-item"
                    value="ARDECODE"
                    onClick={setText2Font}
                  >
                    AR Decode
                  </option>{" "}
                  <option
                    className="dropdown-item"
                    value="georgia"
                    onClick={setText2Font}
                  >
                    Georgia
                  </option>{" "}
                </div>
              </div>
            </div>
          </div>
          <div className="d-none" id="color-palette2">
            <SketchPicker
              color="#000000"
              onChangeComplete={handleColorChange2}
              width="280px"
            />
          </div>

          <div className="form-group">
            <button
              className="btn btn-secondary  overlay-button"
              onClick={startOrder}
            >
              5.Lähetä ja pyydä tarjous
            </button>
          </div>
          <div className="form-group">
            <div
              className="btn-group  overlay-button"
              role="group"
              aria-label="Button group with nested dropdown"
            >
              <button className="btn btn-danger mt-5" onClick={reloadApp}>
                Aloita alusta / tyhjennä
              </button>
              <button
                type="button"
                onClick={getInstructions}
                className="btn  btn-secondary mt-5"
              >
                Ohje
              </button>
            </div>
          </div>
        </div>
      </div>
      <canvas
        ref={canvaselement}
        style={{ display: "block", backgroundColor: "lightgrey" }}
        width={w}
        height={h}
      />
      <input id="fileInput" type="file" style={{ display: "none" }} />
      <input
        id="mainimagefileinputid"
        placeholder="asdfasdf"
        type="file"
        style={{ display: "none" }}
        onChange={(e) => {
          if (e.target.files) {
            bigImage = new Image();
            const file = e.target.files[0];
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onloadend = (e) => {
              bigImage.src = e.target.result;
              $("#imageImg").attr("src", e.target.result);

              bigimagebase64 = e.target.result;
            };
            bigImage.onload = () => {
              let ctxWidth = basectx.canvas.width,
                ctxHeight = basectx.canvas.height;
              let imgWidth = bigImage.width,
                imgHeight = bigImage.height;
              console.log("kuvan orig koko:" + imgWidth + " x " + imgHeight);
              //kuvan leveys / kanvaksen leveys
              let ratioWidth = imgWidth / ctxWidth,
                //kuvan korkeus / kanvaksen korkeus
                ratioHeight = imgHeight / ctxHeight,
                ratioAspect =
                  ratioWidth > 1
                    ? ratioWidth
                    : ratioHeight > 1
                    ? ratioHeight
                    : 1;

              let newWidth = imgWidth / ratioAspect,
                newHeight = imgHeight / ratioAspect;
              basectx.clearRect(0, 0, ctxWidth, ctxHeight);
              //Jos kuva ei ole koko näytön kokoinen kumpaankaan suuntaan
              //Pitää lisäksi katsoa canvaksen koko ja verrata siihen
              //Ratioaspectissa on suoraan oikea suhde riippuen onko leveys vai korkeus suurempi
              //Kuvan kasvatus eli molemmat sivut pienempiä kuin canvas

              if (newWidth < ctxWidth && newHeight < ctxHeight) {
                //Pitää katsoa kumpi ratio suurempi, ratiowidth vai height ja kasvattaa se 100% kokoiseksi
                //ELI
                //Jos suhteellinen leveys lähempänä kuin korkeus
                if (ratioWidth > ratioHeight) {
                  newWidth = newWidth / ratioWidth;
                  newHeight = newHeight / ratioWidth;
                }
                //Jos suhteellinen korkeus lähempänä kuin leveys tai ovat yhtäsuuria
                else {
                  newHeight = newHeight / ratioHeight;
                  newWidth = newWidth / ratioHeight;
                }
              }
              //Kuvan pienennys
              else {
                //Pitää katsoa kumpi menee kanvaksen yli korkeus vai leveys
                //Jos leveys menee yli
                if (newWidth > ctxWidth) {
                  newWidth = ctxWidth;
                  newHeight = (newWidth * imgHeight) / imgWidth;
                }
                //Jos korkeus menee yli
                else if (newHeight > ctxHeight) {
                  newHeight = ctxHeight;
                  newWidth = (imgWidth * newHeight) / imgHeight;
                }
              }
              bigImage.width = newWidth;
              bigImage.height = newHeight;
              basectx.drawImage(
                bigImage,
                0,
                0,
                bigImage.width,
                bigImage.height
              );
            };
          }
        }}
      />
      <input
        type="file"
        style={{ display: "none" }}
        id="miniimagefileinputid"
        onChange={(e) => {
          console.log(e.target.files[0]);
          if (e.target.files) {
            const file = e.target.files[0];
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onloadend = (e) => {
              miniImage.src = e.target.result;
              miniimagebase64 = e.target.result;
            };
            miniImage.onload = () => {
              //Lasketaan kuvalle korkeus. Leveys halutan olevan tietty niin samalla kuvasuhteella lasketaan korkeus
              var imgRealWidth = miniImage.width,
                imgRealHeight = miniImage.height;
              var imgRatio = imgRealWidth / imgRealHeight;
              imgHeight = imgWidth / imgRatio;
              mainDraw();
            };
          }
        }}
      />
      <input
        type="file"
        style={{ display: "none" }}
        id="miniimagefileinputid2"
        onChange={(e) => {
          console.log(e.target.files[0]);
          if (e.target.files) {
            const file = e.target.files[0];
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onloadend = (e) => {
              miniImage2.src = e.target.result;
              miniimage2base64 = e.target.result;
            };
            miniImage2.onload = () => {
              var imgRealWidth = miniImage2.width,
                imgRealHeight = miniImage2.height;
              var imgRatio = imgRealWidth / imgRealHeight;
              imgHeight2 = imgWidth2 / imgRatio;
              mainDraw();
            };
          }
        }}
      />
      <OrderDialog visibility="none" id="order-dialog" onSubmit={sendOrder} />
      <InfoModal
        id="info-modal"
        textHeader="Tilaus lähetetty!"
        textBody="Kiitos tilauksesta. Olemme yhteydessä jos tarvitsemme lisätietoja tilaukseen liittyen."
      />
      <InfoModal
        id="error-modal"
        textHeader="Virhe!"
        textBody="Tilauksen lähetys epäonnistu. Yritäthän myöhemmin uudelleen."
      />
      <InfoModal
        id="sending-modal"
        textHeader="Tilausta lähetetään!"
        textBody="Tilausta lähetetään, odota hetki..."
      />

      <div className="modal fade" id="terms-of-use">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">{"Käyttöehdot"}</h4>
            </div>

            <div className="modal-body">
              {
                "Ohjelman käyttö on sallittu asiakkaan ja Oulun Lasermerkinnän väliseen yhteistyöhön ja kaupalliseen tarkoitukseen. Jatkamalla hyväksyt ehdot."
              }
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-success"
                data-dismiss="modal"
                onClick={setAccept}
              >
                Ok
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade" id="instructions-modal">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">{"Ohjeet"}</h4>
            </div>

            <div className="modal-body">
              <p>
                {
                  "Tällä ohjelmalla voit suunnitella ja vedostaa haluamasi kappaleen merkattavaksi logoilla ja teksteillä ja pyytää tarjouksen Oulun Lasermerkinnältä."
                }
              </p>
              <p>
                {
                  "Merkkaus 1- ja Merkkaus 2- tiedostomuotoina toimivat parhaiten png, jpg sekä svg, joista png ja svg myös läpinäkyvänä ilman taustaa jos kuva on tehty läpinäkyväksi.Tekstirivejä on valittavana kaksi ja niiden fontteja neljä. Jos fontti jokin muu, mainitse lisätiedoissa (kysytään Lähetä ja pyydä tarjous -vaiheessa)."
                }
              </p>

              <p>
                {
                  "Lähetä ja pyydä tarjous- napista täytetään tilaajan yhteystiedot ja Lähetä tiedot -napilla tilaus lähetetään Oulun Lasermerkinnälle käsiteltäväksi. Olethan huolellinen ja täytät yhteystietosi oikein, jotta Oulun Lasermerkinnältä voidaan ottaa yhteyttä tarvittaessa!"
                }
              </p>

              <p>
                <strong>{"Materiaalit ja värit"}</strong>
              </p>
              <p>
                {
                  "Puumateriaalit, Syväkaiverrus teräkseen ja messinkiin => Ruskean sävyisenä. Ruostumaton teräs Kulta-jälkenä tai Hopea, syväkaiverrus tumman ruskea. Tai jossain tilanteessa värimerkintänä ( kallis) Eloksoitu alumiini kaiverrus näkyy valkeana tai eloksoinnin väri jätetään. Muovit ja kerrosmateriaalit, usein vaalean harmaa tai tumman harmaa tai alta paljastuva kerros."
                }
              </p>
              <p>{"Tämä ohje löytyy Ohje-painikkeesta milloin tahansa !"}</p>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-success"
                data-dismiss="modal"
                onClick={setInstructionsRead}
              >
                Ok
              </button>
            </div>
          </div>
        </div>
      </div>

      {/*Pitää laskea kanvaksen koko, voi määrittää vain pikseleinä. Voi olla että kanvaksen koko eri kuin kuvan koko. Ihan sama kuin iso kanvas niin kuvan voi näyttää siinä kokonaan.*/}
    </>
  );
};

export default BaseCanvas;
