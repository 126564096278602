import React from "react";
export const Form = ({ onSubmit, visibility, id }) => {
  const closeForm = () => {
    document.getElementById(id).style.display = "none";
  };
  return (
    <div className="container-fluid" style={{ display: visibility }} id={id}>
      <form onSubmit={onSubmit} className="order-form p-5">
        <div className="form-group row">
          <label htmlFor="name">Nimi</label>
          <input className="form-control" id="name" name="Nimi" />
        </div>
        <div className="form-group row">
          <label htmlFor="streetaddress">Katuosoite</label>
          <input
            className="form-control"
            id="streetaddress"
            name="Katuosoite"
          />
        </div>
        <div className="row">
          <div className="form-group mr-2">
            <label htmlFor="postalcode">Postinumero</label>
            <input
              className="form-control"
              id="postalcode"
              name="Postinumero"
            />
          </div>
          <div className="form-group">
            <label htmlFor="city">Postitoimipaikka</label>
            <input className="form-control" id="city" name="Postitoimipaikka" />
          </div>
        </div>

        <div className="form-group row">
          <label htmlFor="phone">Puhelin</label>
          <input className="form-control" id="phone" name="Puhelin" />
        </div>
        <div className="form-group row">
          <label htmlFor="email">Sähköposti</label>
          <input
            type="email"
            className="form-control"
            id="email"
            placeholder="name@example.com"
            name="Sähköposti"
          />
        </div>
        <div className="form-group row">
          <label htmlFor="info">Lisätiedot</label>
          <textarea
            className="form-control"
            rows="5"
            id="info"
            name="Lisätiedot"
          />
        </div>
        <div className="row">
          <div className="form-group col-6">
            <button
              type="button"
              className="form-control btn btn-danger"
              onClick={closeForm}
            >
              Peruuta
            </button>
          </div>
          <div className="form-group col-6">
            <button className="form-control btn btn-success" type="submit">
              Lähetä tiedot
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};
export default Form;
