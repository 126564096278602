import "./TitleRow.css";
const TitleRow = ({ text, bgcolor }) => {
  return (
    <div style={{ backgroundColor: bgcolor }} className="title-row">
      <b>{text}</b>
    </div>
  );
};

export default TitleRow;
