//import logo from './logo.svg';
//import $ from "jquery";
//import "react-popper/dist/index.umd";
import "bootstrap/dist/js/bootstrap.min.js";
import "bootstrap/dist/css/bootstrap.min.css";
import "jquery/dist/jquery.min.js";

//import { useRef, useLayoutEffect, useState, useEffect } from "react";
import "./App.css";
import TitleRow from "./components/TitleRow";
import BaseCanvas from "./components/BaseCanvas";
//import Test from "./components/OrderDialog";
//var express = require("express");

//import ImageComponent from "./components/imagecomponent.js";

function App() {
  //const canvaselement = useRef(null);
  //let basectx;
  //const [context, setcontext] = useState(null);
  //const [bg, setBg] = useState(null);
  //const [images, setImages] = useState(null);
  return (
    <div className="App">
      <TitleRow
        text="Oulun Lasermerkintä suunnittelutyökalu"
        bgcolor="#F86E2A"
      />
      <BaseCanvas />
    </div>
  );
}

export default App;
